
import React, {useState} from "react"
import Layout from "../../layout"

import WebappsForm from "../../webapps/form"

const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="nntag"

const NNTAGID_BRAND=1;
const NNTAGID_PROMO=99;

const searchFields = [
	{"label":"Name", "dbfield": "nntag_name", "type": "text", "filtertype": "textbox"},
	{"label":"Image", "dbfield": "nntag_image", "type": "image", "filtertype": "image"},
	//{"label":"Banner", "dbfield": "nntag_bannerimage", "type": "image", "filtertype": "image"},
	{"label":"Writeup", "dbfield": "nntag_description", "type": "text", "filtertype": "textbox"}
];

const classificationpickerfields = [
	{"label":"Name", "dbfield": "nntagalias_name", "type": "text", "filtertype": "textbox"}
];

const classificationpickerfilter = [
	{"field":"nntagalias_parent", "operation": "=", "value": 0},
	{"field":"nntagalias_official", "operation": "=", "value": 1},
	{"field":"nntagalias_active", "operation": "=", "value": 1},
	{"field":"nntagalias_id", "operation": "<>", "value": NNTAGID_PROMO}
];

const newFields = [
	[
		{"label":"Name", "field": "nntag_name", "value": "", "input": "textbox", "mode": "unique"},
		{"label":"Classification", "field": "nntagalias_name", "value": "", "input": "picker", "mode":"required", "pickerfields": classificationpickerfields, "pickerfilters": classificationpickerfilter}
	]
];

const formFields = [
	[
		{"label":"Name", "field": "nntag_name", "value": "", "input": "textbox", "mode": "unique"},
		{"label":"Classification", "field": "nntagalias_name", "value": "", "input": "picker", "mode":"readonly"},
	]
];



const formROFields = [
	[
		{"label":"Name", "field": "nntag_name", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Classification", "field": "nntagalias_name", "value": "", "input": "picker", "mode":"readonly"}
	]
];


const formTailFields = [
	[
		{"label":"Webpage", "field": "nntag_url", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/"},
		{"label":"Image", "field": "nntag_image", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":500, "imageheight":400, "imageformat":"jpg", "filehost": "cdn.ngnw.ph", "filelocation":"images/tagimage/uploads", "uploadtype":"https"},
		{"label":"Banner", "field": "nntag_bannerimage", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1200, "imageheight":480, "imageformat":"jpg", "filehost": "cdn.ngnw.ph", "filelocation":"images/tagbanner/uploads", "uploadtype":"https"},
		{"label":"Writeup", "field": "nntag_description", "value": "", "input": "textarea", "mode": "normal"}
	]
];


const TageditorPage = ({location, allowadd}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [nntagaliasname, setNntagaliasname] = useState("");

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		//var customparam = {};
		var customparam = JSON.parse(JSON.stringify(params));

		params.nntagalias_id = 5;// Dev
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
		if (pkid === 0) {
			const blankfieldlist = ["nntag_description", "nntag_image", "nntag_bannerimage", "nntag_promobannerimage", "nntag_promoimage"];
			var blankfieldidx = 0;
			while (blankfieldidx < blankfieldlist.length) {
				if (!params.hasOwnProperty(blankfieldlist[blankfieldidx])) {
					customparam[blankfieldlist[blankfieldidx]] = "";
				}
				blankfieldidx++;
			}
			customparam["nntag_official"] = 1;
		}
		if (params.hasOwnProperty("nntag_name")) {
			customparam.nntag_fullname = nntagaliasname + " | " + params.nntag_name;
		}
		if (params.hasOwnProperty("nntagalias_id") && pkid > 0) {
			customparam.nntag_breadcrumbidlist = params.nntagalias_id + "," + pkid;
		}

		//callback({"status":"Error", "message": JSON.stringify(customparam)}); return;

		webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(response){
			if (pkid === 0) {
				negosyonowAPI.activateEntity(response.documentid,token, "nntag").then(nnactivateresponse => {
					customparam = JSON.parse(JSON.stringify(params));
					customparam.documentid = response.documentid;
					customparam.nntag_breadcrumbidlist = params.nntagalias_id + "," + response.documentid;
					//nntag_breadcrumbidlist
					webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(response){
						negosyonowAPI.buildEntityId(response.documentid,token,"tag").then(nnbuildresponse => {
							callback(response);
						});
					});
				});
			} else {
				negosyonowAPI.buildEntityId(pkid,token,"tag").then(nnupdateresponse => {
					callback(response);
				});
			}
		});
	}

	function customSetFormData(newformdata)
	{
		var nntagalias_name = "";

		if (newformdata.hasOwnProperty("nntagalias_name")) {
			if (newformdata.nntagalias_name) {
				nntagalias_name = newformdata.nntagalias_name;
			}
		}

		//setNntagaliasname(nntagalias_name);
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		var nntagalias_name = "";
		var tmpidx = 0;
		while (tmpidx < mainform.length) {
			if (mainform[tmpidx].field === "nntagalias_name") {
				nntagalias_name = mainform[tmpidx].value;
			}
			tmpidx++;
		}
		setNntagaliasname(nntagalias_name);
		return {"status":"OK"};
	}

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={allowadd}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["nntag_name"]}
				searchFields={searchFields}
				editFields={allowadd?formFields.concat(formTailFields):formROFields.concat(formTailFields)}
				newformFields={allowadd?newFields.concat(formTailFields):[]}
				userSearchFilter={[
					{"field":"nntagalias_id", "operation":"<>", "value": NNTAGID_PROMO},
					{"field":"nntagalias_id", "operation":"<>", "value": NNTAGID_BRAND},
					{"field":"nntag_official", "operation":"<>", "value": 0},
					{"field":"nntag_url", "operation":"<>", "value": ""}
				]}
				entity={ENTITYID}

				customSetFormData={customSetFormData}
				customSubmit={customSubmit}
				customValidateValues={formValidateValues}

				token={token} />
		</Layout>
}


export default TageditorPage;
